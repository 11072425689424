/* LoginPage.module.css */
.loginContainer {
  max-width: 60%;
  margin: auto;
  height: 40%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 8rem;
  background-color: #e8e8e8;
  font-family: "Inter, sans-serif";
}

.inputContainer {
  margin: 27px 0px;
}

.loginButton {
  background-color: blue;
  color: white;
  display: flex;
  padding: 8px 10px;
  border: none;
  justify-content: center;
  font-size: 1.2rem;
  border-radius: 4px;
  width: 90%;
  cursor: pointer;
  margin: 2rem auto;
}

.loginButton:hover {
  background-color: blue;
}

.message {
  margin-top: 15px;
  color: blue;
}

.tab-group {
  width: 100%;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 10px;
}
.tab-group:after {
  content: "";
  display: table;
  clear: both;
}
.tab-group li {
  width: 20rem;
}

.tab-group li button {
  border: none;
  display: block;
  text-decoration: none;
  padding: 10px;
  background: rgba(160, 179, 176, 0.25);
  color: #a0b3b0;
  font-size: 20px;
  float: left;
  width: 100%;
  text-align: center;
  cursor: pointer;
  transition: 0.5s ease;
}

.tab-group li button:hover {
  background: blue;
  color: #ffffff;
}
.tab-group .active button {
  background: blue;
  color: #ffffff;
}

.tab-content > div:last-child {
  display: none;
}

.tab-content > h2 {
  font-size: 2.4rem;
  text-align: center;
  margin: 1rem 0rem;
  font-family: Inter, sans-serif;
}
/* 
.tab-content > div {
  display: none;
} */

.inputContainer > label {
  display: block;
  margin: auto;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  width: 90%;
}
.inputContainer > input {
  display: block;
  width: 90%;
  margin: auto;
  height: 38px;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 17px;
  font-weight: 300;
}
::placeholder {
  color: #000000;
}

.input_container {
  border: 1px solid #030303;
  width: 90%;
  margin: auto;
  margin-bottom: 1.3rem;
}

input[type="file"]::file-selector-button {
  background-color: #b8b8b8;
  color: #000;
  border: 0px;
  border-right: 1px solid #a2a2a2;
  font-weight: bold;
  padding: 10px 15px;
  margin-right: 20px;
  transition: 0.5s;
}

input[type="file"]::file-selector-button:hover {
  background-color: #e9e9e9;
  cursor: pointer;
  border: 0px;
  border-right: 1px solid #e5e5e5;
}

.coolinput,
.select-input {
  display: flex;
  flex-direction: column;
  width: fit-content;
  position: static;
  width: 90%;
  margin: 2rem auto;
}

.select-input select {
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 9px 4px;
  background: #e8e8e8;
  border-radius: 5px;
}
.select-input select > option {
  padding: 7px 2px;
}

.coolinput label.text {
  font-size: 1.1rem;
  color: #818cf8;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 0 3px;
  background: #e8e8e8;
  width: fit-content;
}

.coolinput input[type="text"].input {
  padding: 11px 10px;
  font-size: 1.05rem;
  border: 2px #818cf8 solid;
  border-radius: 5px;
  background: #e8e8e8;
}

.coolinput input[type="text"].input:focus {
  outline: none;
}

.page {
  display: flex;
  justify-content: center;
}

.select-dropdown {
  position: relative;
  background-color: #e6e6e6;
  width: auto;
  float: left;
  max-width: 100%;
  border-radius: 2px;
}

.select-dropdown select {
  font-family: "helvetica neue", helvetica;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  appearance: none;
  padding: 11px 10px;
  font-size: 1.05rem;
  border: 2px #818cf8 solid;
  border-radius: 5px;
  background: #e8e8e8;
  min-width: 367px;
  margin: 2rem 0rem;
}

.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.select-dropdown:after {
  content: " ";
  position: absolute;
  top: 50%;
  margin-top: -2px;
  right: 8px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #aaa;
}
