.login__background-image img {
  position: absolute;
  z-index: -1;
  background-position: cover;
  width: 100vw;
  height: 100vh;
}

.login {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login__become-coins img {
  margin: 2rem;
  margin-left: 15vw;
  width: 10rem;
}

.login__title {
  color: #353a48;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  font-family: "Inter", sans-serif;
}

.login__form {
  display: flex;
  flex-direction: column;
}

.login__form-link {
  width: fit-content;
  margin-left: 35%;
  margin-top: 1.1rem;
  color: #535457;
  text-decoration: none;
  font-weight: medium;
  font-family: "Inter", sans-serif;
}

.login__form-button {
  width: 100%;
  height: 55px;
  margin: 10px 0;
  padding: 10px;
  background-color: #4557dc;
  color: white;
  border: none;
  font-size: 1.2rem;
  border-radius: 5px;
}

.login__form-button:hover {
  cursor: pointer;
  background-color: #4d5fe7;
}

.login__form-password {
  display: flex;
}

.login__form-password-lock {
  margin-top: 20px;
  margin-right: 5px;
  font-size: 2.4rem;
  color: #393939;
}

.login__form-email-input,
.login__form-password-input {
  width: 100%;
  height: 40px;
  margin: 10px 0;
  padding: 10px;
  border: none;
  background-color: transparent;
  font-size: 1rem;
}

.login__form-email-input::placeholder,
.login__form-password-input::placeholder {
  font-weight: medium;
  font-family: "Inter", sans-serif;
}

.login__form-email-input {
  border-bottom: 2px solid #000000;
  width: 95%;
}

.login__form-password-input {
  color: #000000;
  border-radius: 5px;
}

.login__form-password-input::placeholder {
  color: #000000;
}

@media screen and (max-width: 480px) {
  .login {
    width: 80%;
  }
  .login__become-coins img {
    margin-top: -1rem;
    width: 7rem;
    margin: 2.5rem;
  }
  .login__title {
    /* font-size: 9vw; */
    font-weight: 600;
    font-size: 7vw;
    margin-top: -1rem;
    text-align: start;
  }
  .login__form-email-input {
    height: 1rem;
    padding: 1rem;
    padding-left: 0.1rem;
  }
  .login__form-password-input {
    padding: 1rem;
    padding-left: 0.4rem;
    height: 1.4rem;
    width: 100%;
    margin-left: 1.7rem;
  }
  .login__form-password-input::placeholder,
  .login__form-email-input::placeholder {
    font-size: 0.9rem;
  }
  .login__form-password-lock {
    height: 1.6rem;
  }
  .login__form-link {
    width: 80%;
    margin-top: 0.8rem;
    margin-left: 33%;
    font-size: 0.7rem;
  }
  .login__form-password {
    margin-left: -1.4rem;
  }
}
