.myfile {
  width: 100%;
  overflow: hidden;
}
.overlapping__coins {
  display: flex;
  flex-direction: row;
  /* width: 300px; */
  margin-left: -3.5rem;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.coin {
  width: 45px;
  margin: auto;
}
.coin img {
  width: 9rem;
  height: 88px;
}

.myfile__tophalf {
  width: 100%;
  background-color: #0c1d87;
  border-radius: 0px 0px 32px 32px;
  position: relative;
}

.myfile__tophalf__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 44px;
  width: 80%;
  margin: auto;
  margin-top: -1rem;
}

.myfile_tophalf_nav_notification {
  /* display: flex;
  flex-direction: column; */
}

.myfile_tophalf_nav_transaction_count {
  position: absolute;
  margin-left: 1.8rem;
  color: white;
  width: fit-content;
  height: -9rem;
  margin-top: 0.3rem;
  padding: 1px 4px;
  border-radius: 5px;
  background-color: #ff5959;
  text-align: center;
  font-size: 12px;
}
.leaderboard {
  color: whitesmoke;
}

.navbar {
  height: 3rem;
  background-color: #e8eaf5;
  position: fixed;
  z-index: 100;

  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
}

.myfile__tophalf__nav__right_image {
  display: flex;
  justify-content: space-between;
  width: 4rem;
}

.bell {
  width: 1.8rem;
  height: 1.9rem;
  color: white;
  cursor: pointer;
}

.myfile_tophalf_nav_transaction_bell {
  margin: 0.5rem 0.9rem;
}

.myfile__tophalf__nav__right_image img {
  height: 35px;
  object-fit: contain;
  padding-top: 5px;
  cursor: pointer;
}

.myfile__tophalf__nav__left_image {
  height: 35px;
  width: 112px;
  margin-top: -1rem;
}

.myfile__tophalf__content {
  display: flex;
  justify-content: space-between;
  top: 180px;
  width: 80%;
  margin: auto;
  padding-top: 2rem;
}

.myfile__tophalf__content__left {
  display: absolute;
}

.myfile__tophalf__content__left img {
  position: relative;
  padding-top: 1rem;
  height: 104px;
  width: 86px;
}

.myfile__tophalf__content__left span {
  color: white;
  font-size: 64px;
  font-weight: 700;
  line-height: 84px;
  position: absolute;
  top: 8.5rem;
}

.myfile__tophalf__content__left h2 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 23px;
  font-weight: 100;
  position: absolute;
  margin-top: -1rem;
  margin-left: 1rem;
}

.myfile__tophalf__content__right {
  margin-right: 8rem;
}

.myfile__tophalf__content__right h3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  left: 0;
  white-space: nowrap;
}

.myfile__tophalf__content__right ul {
  color: #ffffff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -2rem;
}

.myfile__tophalf__content__right li {
  display: flex;
  align-items: center;
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 20px;
  font-weight: 200;
  margin-top: 0.7rem;
}

.myfile__tophalf__main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.myfile__tophalf__main img {
  margin-left: 3.2rem;
  height: 3rem;
  opacity: 0.5;
}

.myfile__tophalf__main h4 {
  color: #ffffff;
  opacity: 0.7;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.16px;
  line-height: 24px;
  cursor: pointer;
  text-decoration: underline;
}

.myfile__tophalf__main h5 {
  color: #ffffffc7;
  opacity: 0.5;
  font-family: "Inter", Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: -0.1px;
  line-height: 15px;
  margin-top: 0%;
  padding-bottom: 2rem;
  margin-bottom: -1rem;
}
.myfile__light_button {
  background-color: #4557dc85;
  margin-bottom: 0.7rem;
  border: none;
  border-radius: 5px;
  width: 300px;
  opacity: 0.4;
  text-align: center;
  height: 3rem;
  color: white;
}

.modal-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: fit-content;
  width: fit-content;
}

.myfile__light_button button {
  background: none;
  font-size: 1rem;
  border: none;
  margin-top: 0.85rem;
  color: rgba(255, 255, 255, 1);
}

.myfile__light_button:hover {
  cursor: pointer;
  background-color: #4d5fe7;
}

.myfile__dark_button {
  padding: 16px;
  background-color: #4557dc;
  color: rgba(255, 255, 255, 1);
  letter-spacing: -0.36px;
  border: none;
  font-size: 18px;
  padding-top: 1.2rem;
  border-radius: 5px;
  width: 19rem;
  font-weight: 700;
  height: 3rem;
  padding-bottom: 2.3rem;
}

.myfile__dark_button:hover {
  cursor: pointer;
  background-color: #4d5fe7;
}

.myfile__downhalf {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.myfile__downhalf img {
  width: 100px;
  margin-top: 1.2rem;
}

.myfile__downhalf__heading h2 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.24px;
  line-height: normal;
  /* position: absolute;
  left: 647px;
  top: 520px; */
}

.myfile__downhalf__heading h3 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.16px;
  line-height: normal;
  opacity: 0.5;
  padding-bottom: 0%;
  /* position: absolute; */
  /* left: 647px; */
  /* top: 507px; */
}

.myfile__downhalf__heading span {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: -0.8px;
  line-height: normal;
  text-align: right;
  /* left: 829px; */
  /* top: 518px; */
}

.myfile__downhalf__heading {
  width: 290px;
  margin: auto;
}

.myfile__downhalf__heading__rigthpart {
  display: flex;
  justify-content: space-between;
}

.myfile__downhalf h4 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 11px;
  font-weight: 200;
  letter-spacing: -0.14px;
  line-height: 21px;
  opacity: 0.5;
  margin-top: 5px;
  margin-bottom: 2.5rem;
}

.myfile__downhalf__heading__date {
  margin-bottom: -1.3rem;
}

@media screen and (max-width: 750px) {
  .myfile__tophalf__content__right {
    margin-right: 0rem;
  }
}

@media screen and (max-width: 520px) {
  .myfile__tophalf__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 80%;
    margin-top: -1rem;
  }
  .myfile__tophalf__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 44px;
    width: 80%;
    margin: auto;
    margin-top: -1rem;
  }
  .myfile__downhalf {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    height: 95vw;
  }
  .myfile__tophalf__content {
    width: 85%;
    margin: auto;
  }
  .navbar {
    height: 3rem;
    background-color: #e8eaf5;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 2rem;
  }
  .myfile__light_button {
    background-color: #4557dc85;
    margin-bottom: 0.7rem;
    border: none;
    border-radius: 5px;
    /* width: 80%; */
    opacity: 0.4;
    text-align: center;
    height: 3rem;
    padding: 0.2rem;
  }
  .myfile__light_button button {
    background: none;
    font-size: 1rem;
    border: none;
    margin-top: 0.85rem;
    color: rgba(255, 255, 255, 1);
  }
  .myfile__tophalf__content__left span {
    font-size: 58px;
  }
  .myfile__tophalf__content__left img {
    height: 94px;
    width: 79px;
    margin-bottom: 1rem;
  }
  .myfile__tophalf__content__left h2 {
    margin-top: -1.8rem;
  }
  .myfile__tophalf__main__user {
    display: flex;
    flex-direction: row;
    width: 105%;
    margin-bottom: 1rem;
    margin-top: -2rem;
  }

  .myfile__tophalf__main__user__img {
    cursor: pointer;
  }

  .myfile__tophalf__main img {
    margin-right: 1rem;
    height: 3rem;
  }
  .myfile__tophalf__main h4 {
    color: #ffffff;
    opacity: 0.7;
    font-family: "Inter", Helvetica;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: -0.16px;
    line-height: 24px;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 0.6rem;
    margin-left: -0.5rem;
  }
  .myfile__tophalf__main img {
    height: 2.5rem;
  }
}

@media screen and (min-width: 521px) and (max-width: 650px) {
  .myfile__tophalf__content {
    width: 80%;
  }
}
@media screen and (max-width: 350px) {
  /* .myfile__downhalf img {
      width: 20px;
      margin-top: 1.2rem;
    } */
}
