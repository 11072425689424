/* Variables */
:root {
  --container-max-width: 600px;
  --container-color: white;
  --button-padding: 10px 20px;
  --button-border-radius: 5px;
  --button-background-color: white;
  --button-color: #0072ff;
  --button-font-size: 1.2rem;
}

/* App component */
.App {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c1d87;
}

/* Container */
.container {
  max-width: var(--container-max-width);
  text-align: center;
  color: var(--container-color);
}

/* Header 1 */
.container h1 {
  font-size: 5rem;
}

/* Paragraph */
.container p {
  font-size: 1.5rem;
}

/* Button */
.container button {
  margin-top: 20px;
  padding: var(--button-padding);
  border: none;
  cursor: pointer;
  border-radius: var(--button-border-radius);
  background-color: var(--button-background-color);
  color: var(--button-color);
  font-size: var(--button-font-size);
}

.container button:hover {
  background-color: rgb(229, 241, 253);
}
