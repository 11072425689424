.notes-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  justify-content: center;
  background-color: #007bff; /* Changed background to blue */
  height: 100vh; /* Make height 100% of the screen */
}

.note-card {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.3s ease;
  height: 200px; /* Make height 100% of the container */
}

.note-card:hover {
  transform: translateY(-5px);
}

.note-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.note-message {
  color: #666;
  font-size: 16px;
  line-height: 1.5;
}

.note-date {
  color: #999;
  font-size: 14px;
  text-align: right;
  margin-top: 15px;
}

.note-paragraph {
  color: #444;
  font-size: 15px;
  line-height: 1.6;
  margin-top: 5px;
  margin-bottom: 5px;
}

.blur-notes {
  filter: blur(8px);
}
