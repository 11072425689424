.transaction {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 10px;
  width: 100%;
  color: white;
  margin-top: 1rem;
}

.transaction__image {
  width: 50px;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.transaction__image img {
  position: absolute;
  /* margin-top: -1.6rem; */
  margin-left: 0.6rem;
  width: 41px;
  opacity: 0.8;
}

.transaction__content {
  flex: 1;
}

.transaction__content-value {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.674);
  font-size: 14px;
  margin-bottom: 5px;
}

.transaction__content-date {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.674);
  font-size: 14px;
  margin-bottom: 5px;
}

.transaction__content-name {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.transaction__content-description {
  font-size: 14px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.674);
  line-height: 1.5;
}

.transaction__image {
  /* margin-top: -2rem; */
}

.transaction__content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.transaction__content-amount {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 0.6rem;
}

@media screen and (max-width: 540px) {
  .transaction {
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    width: 100%;
    color: white;
    margin-top: 1.3rem;
  }
  .transaction__content-date {
    font-size: 11px;
    margin-bottom: 5px;
  }
  .transaction__content-value {
    font-weight: 500;
  }

  .transaction__content-name {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .transaction__content-description {
    font-size: 12px;
    font-weight: 100;
    width: 95%;
    color: rgba(255, 255, 255, 0.674);
    line-height: 1.5;
  }
  .transaction__image img {
    margin-top: 0rem;
  }
}
@media screen and (min-width: 531px) and (max-width: 545px) {
  .transaction__image img {
    position: absolute;
    /* margin-top: -1rem; */
    margin-left: 0.6rem;
  }
  .transaction__content-value {
    font-weight: 500;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .transaction__image img {
    position: absolute;
    /* margin-top: -1rem; */
    margin-left: 0.6rem;
  }
  .transaction__content-value {
    font-weight: 500;
  }
}

@media screen and (min-width: 1320px) {
  .transaction__image img {
    position: absolute;
    /* margin-top: -1rem; */
    margin-left: 0.6rem;
  }
  .transaction__content-value {
    font-weight: 500;
  }
}
