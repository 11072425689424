.profile__content-coins {
  display: flex;
  align-items: center;
  width: 100%;
  width: fit-content;
  margin: 0.8rem 2rem;
}

.profile__content {
  margin-top: 0.8rem;
}

.profile__content-name {
  color: black;
  text-align: center;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  margin: auto;
  font-size: 1rem;
}

.profile__content-coins-count,
.profile__content-coins-image {
  font-family: "Inter", sans-serif;
}

.profile__content-coins-count {
  color: rgb(102, 102, 102);
  font-weight: 400;
  font-size: 1.2rem;
}

.profile__content-coins-image {
  height: 30px;
}

.profile__content-coins-image img {
  margin-top: 0px;
  margin-right: 0rem;
  width: 1.5rem;
}

.profile__content-coins-count,
.profile__content-coins-image {
  height: 30px;
}

.profile__picture img {
  width: 125px;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (max-width: 500px) {
  .profile__picture img {
    width: 100px;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  .profile__content-name {
    font-size: 1rem;
  }
  .profile__content-coins {
    display: flex;
    align-items: center;
    width: 100%;
    width: fit-content;
    margin: 0.8rem 1.9rem;
  }
}
