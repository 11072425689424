.profile {
  color: white;
  min-height: 100vh;
  background-color: #0c1d87;
  font-family: "Inter", sans-serif;
  overflow: hidden;
}
.transaction__image {
  margin-top: 0rem;
  height: 100%;
  margin-top: 3rem;
}

.navbar {
  height: 3rem;
  background-color: #e8eaf5;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  margin-top: 20px;
}
.profile__secondpart {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: auto;
}
.profile__name {
  width: 80%;
  margin: 1.4rem auto;
}
.profile__secondpart-transaction {
  width: 50%;
  /* margin: auto; */
}

.profile__secondpart-content {
  width: 100%;
}
.profile__secondpart-values {
  width: 25%;
  margin-bottom: 1.5rem;
}

.profile__greeting {
  display: flex;
  width: 80%;
  margin: auto;
  margin-bottom: -1rem;
}

.profile__secondpart-title {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.632);
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
}
.profile__secondpart-title button {
  background: none;
  color: rgba(255, 255, 255, 0.632);
  border: none;
  font-size: 0.75rem;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  border: none;
  background: none;
  margin-left: 5rem;
  cursor: pointer;
  text-decoration: underline;
}
.profile__secondpart-title button:hover {
  color: white;
}

.profile__secondpart-transaction {
  margin-left: 18rem;
}

.profile__secondpart-buttons-1 {
  margin-bottom: 20px;
  padding: 9px;
  border: none;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--button-background-color);
  color: blue;
  font-size: 0.8rem;
  margin-right: 0.7rem;
}
.profile__secondpart-buttons-2 {
  background-color: #4557dc85;
  color: rgb(255, 255, 255);
  margin-bottom: 0.7rem;
  border: none;
  border-radius: 5px;
  opacity: 0.4;
  width: 6rem;
  font-size: 0.7rem;
  text-align: center;
  height: 2rem;
}

.profile__secondpart-title button {
  text-decoration: underline;
}

@media screen and (min-width: 950px) {
  .profile__secondpart {
    display: flex;
    justify-content: none;
    width: 80%;
    margin: auto;
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 950px) and (max-width: 1180px) {
  .profile__secondpart {
    display: flex;
    justify-content: none;
    width: 80%;
    margin: auto;
    margin-bottom: 5rem;
  }
  .profile__secondpart-transaction {
    margin-left: 2vw;
  }
  .profile__name {
    width: 75vw;
    margin: 1.4rem auto;
  }
}

@media screen and (max-width: 980px) {
  .profile__secondpart {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 5rem;
  }
  .profile__name {
    width: 70vw;
    margin: 1.4rem auto;
  }
  .profile__secondpart-transaction {
    margin-left: 1vw;
  }
  .profile__secondpart-values {
    width: 90%;
    margin: auto;
    margin-bottom: 3.5rem;
  }
  .profile__secondpart-transaction {
    width: 90%;
    margin: auto;
  }
}

@media screen and (max-width: 890px) {
  .profile__name {
    width: 65vw;
    margin: 1.4rem auto;
  }
  .profile__secondpart-title {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.632);
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
  }
  .profile__secondpart-title button {
    border: none;
    background: none;
    color: white;
    width: fit-content;
    margin: 0;
    font-size: 0.7rem;
    margin-left: 1.3rem;
    cursor: pointer;
    text-decoration: underline;
  }
}
@media screen and (max-width: 890px) {
  .profile__secondpart-title button {
    border: none;
    background: none;
    color: white;
    width: fit-content;
    margin: 0;
    color: rgba(255, 255, 255, 0.632);
    border: none;
    font-size: 0.6rem;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    cursor: pointer;
    text-decoration: underline;
  }
}

@media screen and (max-width: 440px) {
  .profile__greeting {
    display: flex;
    width: 80%;
    margin: auto;
    margin-bottom: -1rem;
  }
  .profile__greeting {
    display: flex;
    width: 68%;
    margin: auto;
    margin-bottom: -1rem;
  }

  .profile__secondpart {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-bottom: 5rem;
    width: 90%;
  }
  .profile__greeting {
    display: flex;
    width: 80%;
    margin: auto;
    margin-bottom: -2rem;
    margin-top: -0.7rem;
  }
}
