.all-transaction {
  background-color: #0c1d87;
  color: white;
  min-height: 100vh;
}
.navbar {
  height: 3rem;
  background-color: #e8eaf5;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  margin-top: 20px;
}
.profile__secondpart-transaction {
  padding: 3rem 4rem;
}

.profile__secondpart-title {
  color: hsla(0, 0%, 100%, 0.632);
  font-size: 1.05rem;
  font-weight: 600;
  margin-bottom: -0.5rem;
  margin-top: -1rem;
  text-align: start;
  margin-left: auto;
  width: 92%;
  font-family: "Inter", sans-serif;
}

.profile__secondpart-content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 3rem;
  width: 2rem;
}

.transaction__content-value {
  font-weight: 500;
}

.transaction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 40%;
  color: white;
}

.transaction__image {
  width: 50px;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}

.transaction__image img {
  position: absolute;
  margin-top: -1.6rem;
  margin-left: 0.6rem;
  width: 41px;
  opacity: 0.8;
}

.transaction__content {
  flex: 1;
  width: 100%;
  font-family: "Inter", sans-serif;
  /* font-weight: 100; */
}

.transaction__content-date {
  font-weight: 100;
  color: rgba(255, 255, 255, 0.674);
  font-size: 14px;
  margin-bottom: 5px;
}

.transaction__content-name {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.transaction__content-value {
  font-weight: 100;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.674);
  margin-bottom: 0.2rem;
  text-align: center;
}

.transaction__amount-count-red,
.transaction__amount-count-green {
  font-size: 12px;
}
.transaction__amount-count-red {
  color: #ff5959;
}
.transaction__amount-count-green {
  color: rgb(64, 255, 47);
}

.transaction__content-arrow {
  width: 100%;
  text-align: center;
}

.transaction__amount-count {
  display: flex;
  justify-content: space-between;
}

.transaction__content-description {
  font-size: 14px;
  font-weight: 100;
  color: rgba(255, 255, 255, 0.674);
  line-height: 1.5;
}

.transaction__image {
  /* margin-top: -2rem; */
}

.transaction__content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.transaction__content-amount {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  margin-right: 0.1rem;
}

.transaction__details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transaction__image {
  height: 100%;
  margin-top: 20rem;
}

@media screen and (max-width: 540px) {
  .transaction {
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    color: white;
  }
  .transaction__image {
    margin-top: 0rem;
    height: 100%;
    margin-top: 1.5rem;
  }
  .transaction__content-date {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .transaction__content-name {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .transaction__image img {
    position: absolute;
    margin-left: 0.2rem;
  }
  .transaction__content {
    flex: 1;
    width: 100%;
    font-family: "Inter", sans-serif;
    margin-left: -0.6rem;
  }

  .transaction__content-description {
    font-size: 12px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.674);
    line-height: 1.5;
    width: 100%;
  }

  .profile__secondpart-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 100%;
    margin-bottom: 2rem;
  }
  .profile__secondpart-transaction {
    padding: 1rem 0.7rem;
  }
}

@media screen and (min-width: 531px) and (max-width: 750px) {
  .transaction__image img {
    position: absolute;
    margin-top: -1rem;
    margin-left: 0.6rem;
  }
  .transaction {
    display: flex;
    align-items: flex-start;
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    color: white;
  }
  .transaction__image {
    margin-top: 0rem;
    height: 100%;
    margin-top: 1rem;
  }
  .transaction__content-date {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .transaction__content-name {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .transaction__image img {
    position: absolute;
    margin-left: 0.6rem;
  }

  .transaction__content-description {
    font-size: 12px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.674);
    line-height: 1.5;
    width: 100%;
  }

  .profile__secondpart-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 100%;
    margin-bottom: 2rem;
  }
  .profile__secondpart-transaction {
    padding: 3rem 1.7rem;
  }
}

@media screen and (min-width: 751px) and (max-width: 1160px) {
  .transaction__image img {
    position: absolute;
    margin-top: 0rem;
    margin-left: 0.6rem;
  }
  .transaction {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 40%;
    color: white;
  }
  .transaction__image {
    margin-top: 0rem;
    height: 100%;
    /* margin-top: 1rem; */
  }
  .transaction__content-date {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .transaction__content-name {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .transaction__image img {
    position: absolute;
    margin-left: 0.6rem;
  }

  .transaction__content-description {
    font-size: 12px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.674);
    line-height: 1.5;
    width: 100%;
  }

  .profile__secondpart-content {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 100%;
  }
  .profile__secondpart-transaction {
    padding: 3rem 1.7rem;
  }
}

@media screen and (min-width: 1151px) {
  .profile__secondpart-title {
    font-weight: 600;
    width: 80%;
    margin: auto;
    text-align: start;
    color: rgba(255, 255, 255, 0.632);
    font-size: 1.2rem;
    margin-bottom: 3rem;
    margin-top: -1rem;
  }
  .transaction__image img {
    position: absolute;
    margin-top: 0rem;
    margin-left: 0.6rem;
  }
  .transaction {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 10px;
    width: 40%;
    color: white;
  }
  .transaction__image {
    margin-top: 0rem;
    height: 100%;
    /* margin-top: 1rem; */
  }
  .transaction__content-date {
    font-size: 11px;
    margin-bottom: 5px;
  }

  .transaction__content-name {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 5px;
  }

  .transaction__image img {
    position: absolute;
    margin-left: 0.6rem;
  }

  .transaction__content-description {
    font-size: 12px;
    font-weight: 100;
    color: rgba(255, 255, 255, 0.674);
    line-height: 1.5;
    width: 100%;
  }

  .profile__secondpart-content {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
    margin: auto;
    flex-wrap: wrap;
    margin-top: 2rem;
    width: 90%;
  }
  .profile__secondpart-transaction {
    padding: 3rem 1.7rem;
  }
}

@media screen and (max-width: 390px) {
  /* .transaction__content-arrow {
    position: absolute;
    margin: 9rem;
  } */
}
