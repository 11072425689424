/* Base styles remain the same */
.page-container {
  position: relative;
  min-height: 100vh;
  background-color: #f9fafb;
  padding: 2rem;
  overflow-x: hidden;
  margin-bottom: 4rem;
}

/* Floating note styles */
.navbar {
  height: 3rem;
  background-color: #e8eaf5;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin-top: 20px;
}

.floating-note {
  position: absolute;
  width: 12rem;
  height: 12rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 0;
}

/* Fixed jar note styles */
.jar-note {
  position: absolute;
  width: 8rem;
  height: 8rem;
  padding: 0.75rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

/* Note colors */
.note-orange {
  background-color: #fff3e0;
}
.note-purple {
  background-color: #f3e5f5;
}
.note-blue {
  background-color: #e3f2fd;
}

/* Note text styles */
.floating-note .note-text {
  font-size: 0.875rem;
  color: #4b5563;
  flex-grow: 1;
}

.jar-note .note-text {
  font-size: 0.75rem;
  color: #4b5563;
  flex-grow: 1;
}

.note-label {
  font-size: 0.75rem;
  color: #6b7280;
  text-align: right;
  padding-top: 0.5rem;
}

/* Rotations */
.rotate-neg-12 {
  transform: rotate(-12deg);
}
.rotate-6 {
  transform: rotate(6deg);
}
.rotate-3 {
  transform: rotate(3deg);
}
.rotate-neg-6 {
  transform: rotate(-6deg);
}
.rotate-12 {
  transform: rotate(12deg);
}

/* Fixed jar note positions */
.jar-note-1 {
  top: 22%;
  left: 43%;
  transform: translateX(-50%) rotate(14deg);
  width: 11rem;
  height: 13rem;
}

.jar-note-2 {
  top: 37%;
  left: 58%;
  transform: translateX(-50%) rotate(-13deg);
  width: 11rem;
  height: 12rem;
}

.jar-note-3 {
  top: 59%;
  left: 48%;
  transform: translateX(-50%) rotate(-2deg);
  width: 11rem;
  height: 12rem;
}

/* Main content */
.main-content {
  max-width: 32rem;
  position: relative;
  z-index: 1;
}

.title {
  font-size: 2.75rem;
  font-weight: 700;
  color: #f97316;
  margin-bottom: 1.5rem;
}

.paragraph {
  color: #4b5563;
  margin-bottom: 14px;
  line-height: 1.5;
  font-size: 19px;
  font-weight: 400;
}

.button-container {
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
}

.button {
  background-color: #f97316;
  color: white;
  padding: 0.8rem 1.7rem;
  border-radius: 9999px;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #ea580c;
}

/* Jar styles */
.jar-container {
  right: 5rem;
  top: 10rem;
  width: 26rem;
  margin-top: 3rem;
  height: 24rem;
}

.jar-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.jar-lid {
  position: absolute;
  top: 0;
  left: 51%;
  transform: translateX(-50%);
  width: 13rem;
  height: 3rem;
  /* background-color: #e5e7eb; */
  border-top-left-radius: 1.125rem;
  border-top-right-radius: 1.125rem;
  z-index: 2;
  border: 2px solid #a5a6a7;
}

.lid-lines {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding-top: 1.25rem;
  color: #a5a6a7;
}

.lid-line {
  width: 4rem;
  height: 0.125rem;
  background-color: #d1d5db;
}

.full-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 6rem;
  gap: 5rem;
  flex-wrap: wrap;
}

.jar-body {
  position: absolute;
  top: 3rem;
  left: 51%;
  transform: translateX(-50%);
  width: 20rem;
  height: 29rem;
  border: 2px solid #a5a6a7;
  border-top-left-radius: 3.5rem;
  border-top-right-radius: 3.5rem;
  border-bottom-left-radius: 3.5rem;
  border-bottom-right-radius: 4.5rem;
  overflow: hidden;
}

.jar-neck {
  position: absolute;
  top: -0.125rem;
  left: 50%;
  transform: translateX(-50%);
  width: 13rem;
  height: 1rem;
  border: 2px solid #d1d5db;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

/* Mobile Responsiveness */
@media screen and (max-width: 1024px) {
  .jar-container {
    position: relative;
    right: auto;
    top: auto;
    width: 100%;
    margin-top: 2rem;
  }

  .main-content {
    max-width: 100%;
  }

  .floating-note {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .page-container {
    padding: 1rem;
    height: 100vh;
  }

  .main-content {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .jar-container {
    position: relative;
    right: auto;
    top: auto;
    width: 12rem;
    height: 20rem;
    margin: 2rem auto;
  }

  .floating-note {
    width: 10rem;
    height: 10rem;
  }

  .jar-note {
    width: 6rem;
    height: 6rem;
  }

  .title {
    font-size: 1.875rem;
  }

  .floating-note .note-text {
    font-size: 0.75rem;
  }

  .jar-note .note-text {
    font-size: 0.625rem;
  }

  .note-label {
    font-size: 0.625rem;
  }
}

@media screen and (max-width: 480px) {
  .floating-note {
    width: 8rem;
    height: 8rem;
  }

  .jar-container {
    width: 10rem;
    height: 16rem;
  }

  .jar-note {
    width: 11rem;
    height: 11rem;
  }

  .title {
    font-size: 1.5rem;
  }

  .floating-note .note-text {
    font-size: 0.625rem;
  }

  .jar-note .note-text {
    font-size: 1rem;
  }
}

/* Hide some floating notes on very small screens */
@media screen and (max-width: 380px) {
  .floating-note:nth-child(4),
  .floating-note:nth-child(5) {
    display: none;
  }
}

/* Modal styles */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
}

.modal-close {
  float: right;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal h2 {
  color: #f97316;
  margin-bottom: 1rem;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal form label {
  margin-bottom: 1rem;
}

.modal form input,
.modal form select,
.modal form textarea {
  width: 100%;
  padding: 0.5rem;
  border: 2px solid #ddd;
  border-radius: 0.25rem;
}

.modal-submit {
  background-color: #f97316;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.modal-submit:hover {
  background-color: #ea580c;
}

/* Hide all floating notes on mobile and tablet screens */
@media screen and (max-width: 1024px) {
  .floating-note {
    display: none;
  }
  .full-container {
    display: flex;
    margin-top: 0;
    gap: 0;
  }
}
