.profile__firstpart {
  display: flex;
  flex-direction: row;
  width: 60%;
  justify-content: space-around;
  padding: 20px;
  margin-bottom: 3rem;
  margin-top: 1.5rem;
  font-family: "Inter", sans-serif;
}

.leaderboard-coin {
  margin-top: -0.1rem;
  margin-right: -0.1rem;
}
.leaderboard-coin > img {
  height: 1.9rem;
}

.leaderboard-single-person {
  display: flex;
  flex-direction: row;
  /* margin-left: 1rem; */
}

.profile__firstpart-coins {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.profile__firstpart-coins-2 {
  width: 85%;
  margin: auto;
}
.coins__content img {
  height: 4.5rem;
  margin-bottom: -1.3rem;
  margin-right: 1.3rem;
}

.coins__content {
  display: flex;
  align-items: center;
  border-radius: 50%;
}

.total__coins {
  font-size: 76px;
  font-weight: bold;
  margin-left: -27px;
}

.coins_text {
  font-size: 25px;
  margin-left: 1.8rem;
}

.profile__firstpart-leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2.3rem;
  margin-top: 0.7rem;
}

.leaderboard-number {
  font-size: 23px;
  font-weight: bold;
}

.leaderboard-single-person {
  text-align: center;
  margin-bottom: -2px;
  font-weight: 400;
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  padding: 0px;
}

.profile__firstpart-2 {
  width: 68%;
  margin: auto;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
  margin-left: 2.7rem;
}

.leaderboard__light_button {
  background: none;
  color: rgba(255, 255, 255, 0.459);
  border: none;
  font-size: 10px;
  border-radius: 5px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  width: 130px;
  margin-left: -5rem;
  text-decoration: underline;
}

.leaderboard-name {
  font-size: 23px;
  margin-right: 2px;
}

.leaderboard-heading {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.632);
  margin-bottom: 0.7rem;
  margin-left: -2rem;
}
.leaderboard-name {
  margin-left: 2px;
}

@media screen and (min-width: 701px) and (max-width: 1030px) {
  .profile__firstpart {
    width: 95%;
  }
  .profile__firstpart-coins-2 {
    width: 80%;
  }
  .leaderboard-coin {
    margin-top: -0.1rem;
    margin-right: -0.1rem;
  }
}

@media screen and (min-width: 561px) and (max-width: 700px) {
  .profile__firstpart {
    width: 90%;
  }
  .leaderboard-coin {
    margin-top: -0.1rem;
    margin-right: -0.2rem;
  }
  .coins__content img {
    height: 4.5rem;
    margin-bottom: -1.3rem;
    margin-right: 1.3rem;
  }
  .leaderboard-coin > img {
    height: 1.7rem;
  }
  .profile__firstpart-coins-2 {
    width: 80%;
  }
}

@media screen and (min-width: 480px) and (max-width: 560px) {
  .total__coins {
    font-size: 69px;
  }
  .leaderboard-coin {
    margin-top: -0.4rem;
    margin-right: -0.2rem;
  }
  .leaderboard-heading {
    font-weight: 600;
    color: rgba(255, 255, 255, 0.632);
    margin-bottom: 0.7rem;
  }

  .coins_text {
    font-size: 20px;
  }
  .leaderboard-name {
    font-size: 20px;
  }
  .leaderboard-number {
    font-size: 20px;
    font-weight: bold;
  }
  .profile__firstpart-coins-2 {
    width: 92%;
  }
  .profile__firstpart {
    flex-direction: row;
    width: 70%;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    justify-content: space-between;
  }
  .coins__content img {
    height: 3rem;
    margin-bottom: -0.6rem;
    margin-right: 0.5rem;
  }
  .total__coins {
    font-size: 76px;
    font-weight: bold;
    margin-left: -27px;
  }

  .coins_text {
    font-size: 25px;
    margin-left: 1.8rem;
  }
}

.leaderboard__light_button {
  background: none;
  color: rgba(255, 255, 255, 0.459);
  border: none;
  font-size: 10px;
  border-radius: 5px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  width: 130px;
  margin-left: -5.2rem;
  text-decoration: underline;
}

.leaderboard__light_button:hover {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.459);
}

@media screen and (max-width: 479px) {
  .profile__firstpart {
    width: 89%;
    margin: auto;
    margin-bottom: 0rem;
    margin-top: 2rem;
    gap: 4rem;
  }
  .leaderboard-coin {
    margin-top: -0.2rem;
    margin-right: -0.1em;
    margin-left: 2px;
  }
  .leaderboard-coin > img {
    height: 1.6rem;
    width: 1.5rem;
  }
  .profile__firstpart-2 {
    width: 106%;
    margin: auto;
    margin-bottom: 2rem;
    margin-top: 1.5rem;
    margin-left: 1.4rem;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
  .profile__firstpart-coins-2 {
    width: 92%;
  }
  .leaderboard-heading {
    font-size: 11px;
    margin-left: -1rem;
  }
  .total__coins {
    font-size: 76px;
    font-weight: bold;
    margin-left: -13px;
  }
  .leaderboard__light_button {
    background: none;
    color: rgba(255, 255, 255, 0.459);
    border: none;
    font-size: 8px;
    border-radius: 5px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    width: 130px;
    text-decoration: underline;
    margin-left: -3.4rem;
  }

  .coins_text {
    margin-left: 1rem;
    font-size: 20px;
  }
  .leaderboard-number {
    font-size: 15px;
    font-weight: bold;
  }
  .total__coins {
    font-size: 45px;
  }
  .leaderboard-name {
    font-size: 15px;
  }
  .coins__content img {
    height: 3rem;
    margin-bottom: -0.6rem;
    margin-right: 0.5rem;
  }
}

/* @media screen and (max-width: 479px) {
  .coins_text {
    margin-left: 1rem;
    font-size: 16px;
  }
} */
