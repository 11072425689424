.loading {
  background-color: rgb(255, 255, 255);
  height: 100vh;
}

.loading-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
}
