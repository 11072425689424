.allUsers__leaderboard {
  background-color: #0c1d87;
  min-height: 100vh;
  overflow: hidden;
}

.leaderboard {
  color: white;
}
.navbar {
  height: 3rem;
  background-color: #e8eaf5;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  margin-top: 20px;
}
.leaderboard__heading {
  padding: 3rem 10rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.632);
  font-family: "Inter", sans-serif;
  font-size: 1.2rem;
}

.leaderboard-single-person {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: 400;
  background: none;
  color: white;
  border: none;
  padding: 0px;
}

.leaderboard__Users {
  width: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: fit-content;
  margin: auto;
  margin-left: 10rem;
  margin-bottom: 3rem;
}

.leaderboard-number {
  font-size: 26px;
  font-weight: bold;
}

.leaderboard-name {
  font-size: 29px;
  margin-left: 1rem;
}

.values {
  display: flex;
  align-items: center;
  width: 65%;
  font-family: "Inter", sans-serif;
  margin-bottom: 1.6rem;
}

.values__content {
  flex: 1;
  font-size: 20px;
  font-weight: 400;
  border: none;
  background: none;
  color: white;
  width: fit-content;
  text-align: start;
  cursor: pointer;
}

.values__content-right {
  display: flex;
  align-items: center;
}

.values__content-right-coin {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.values__content-right-coin img {
  width: 80%;
  height: 80%;
  margin-top: 1px;
}

/* .values__content-coin img {
  margin-right: -0.5rem;
} */

.leaderboard-number {
  margin-right: 1rem;
}

.values__content-value {
  font-size: 20px;
  font-weight: 600;
}

.values__content-coin img {
  margin-top: 0.3rem;
  width: 2rem;
}

@media screen and (max-width: 500px) {
  .leaderboard__heading {
    padding: 8vw 5vh;
    text-align: start;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.632);
    font-size: 1rem;
    margin-top: -2rem;
  }
  .leaderboard__Users {
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: fit-content;
    margin: auto;
    margin-left: 10rem;
    margin-bottom: 2rem;
  }
  .values__content {
    font-size: 18px;
  }
  .leaderboard-number {
    font-size: 20px;
    font-weight: 500;
  }
  .values__content-value {
    font-size: 19px;
  }
  .values__content-coin img {
    margin-top: 0.5rem;
    width: 2rem;
  }
  .leaderboard {
    color: white;
    width: 90%;
    margin: -0.3rem auto;
  }

  .values {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    margin-bottom: 0.7rem;
    width: 100%;
  }
  .leaderboard__Users {
    margin-top: 1rem;
  }
  .leaderboard__Users {
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: fit-content;
    margin: auto;
    width: 85%;
    margin: auto;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }
}

@media screen and (min-width: 500px) and (max-width: 900px) {
  .leaderboard__heading {
    padding: 10vw 7vh;
    text-align: center;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.632);
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .leaderboard__Users {
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: fit-content;
    margin: auto;
    margin-bottom: 2rem;

    /* margin-left: 10rem; */
  }
  .values__content {
    font-size: 18px;
  }
  .leaderboard-number {
    font-size: 20px;
    font-weight: 500;
  }
  .values__content-value {
    font-size: 19px;
  }
  .values__content-coin img {
    margin-top: 0.5rem;
    width: 2rem;
  }
  .leaderboard {
    color: white;
    width: 90%;
    margin: 0rem auto;
  }

  .values {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    margin-bottom: 0.7rem;
    width: 100%;
  }
  .leaderboard__Users {
    margin-top: 1rem;
  }
  .leaderboard__Users {
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: fit-content;
    margin: auto;
    width: 85%;
    margin: auto;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
  }
  .leaderboard__heading {
    padding: 8vw 12vh;
    text-align: start;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.632);
    font-size: 1.2rem;
    margin-top: -2rem;
  }
  .leaderboard__Users {
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: fit-content;
    margin: auto;
    margin-bottom: 2rem;
  }

  .values {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    margin-bottom: 0.6rem;
    width: 100%;
    margin: auto;
    font-size: 1.9rem;
  }

  .values__content {
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
  }
  .values__content-value {
    font-size: 1.5rem;
  }
  .values__content-coin img {
    margin-top: 1rem;
    width: 2rem;
    /* margin-right: -0.7rem; */
  }
  .leaderboard__Users {
    width: 70%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
}
