.sendPage__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  margin: 4.5rem 4rem;
  overflow: hidden;
}

.sendPage__content-user-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 0rem;
}

.sendpage__content-profile {
  margin-right: 10px;
}

.sendpage__content-coin {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 57px;
}

.sendpage__content-coin-amount {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  color: #0c1d87;
  font-weight: bold;
  margin-right: -59px;
}

.sendPage__content-input {
  width: 80%;
  margin-top: 3.5rem;
}

.sendPage__content-radio {
  display: flex;
  flex-direction: column;
}

.sendPage__content-radio label {
  font-size: 1rem;
}

.sendPage__content-radio select {
  border: 2px solid #acaaaa;
  padding: 1.1rem 1rem;
  border-radius: 5px;
  margin-top: 0.3rem;
  font-size: 1.1rem;
  background-color: rgba(245, 245, 245, 0.507);
  border: 1px solid rgba(204, 204, 204, 0.68);
  color: rgb(18, 18, 18);
}

.navbar {
  height: 3rem;
  background-color: #e8eaf5;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 2rem;
}

.sendPage__content-radio select option {
  margin-top: 1rem;
}

.sendPage__content-text {
  display: flex;
  flex-direction: column;
  margin: 28px auto;
}

.sendPage__content-text label {
  font-weight: 400;
}

.sendPage__content-text label,
.sendPage__content-radio label {
  font-family: "Inter", sans-serif;
}

.sendPage__content-text textarea {
  text-align: start;
  border-radius: 5px;
  margin-top: 0.3rem;
  padding: 1rem;
  font-size: 1.1rem;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgba(204, 204, 204, 0.68);
}

.sendPage__content-button {
  width: 54%;
}

.sendpage__content-coin img {
  width: 100px;
  margin-bottom: 1rem;
  margin-left: 5rem;
  margin-top: 2rem;
}

@media screen and (min-width: 613px) and (max-width: 820px) {
  .sendPage__content {
    width: 98%;
    margin: auto;
  }
  .sendPage__content-button {
    width: 100%;
    margin: auto;
    font-weight: 600;
  }
  .sendpage__content-coin img {
    width: 20vw;
  }
}

@media screen and (max-width: 612px) {
  .sendPage__content-radio select {
    padding: 3.5vw 1vw;
    font-size: 4vw;
  }
  .sendPage__content-input {
    width: 85%;
    margin-top: 1rem;
  }
  .sendPage__content-user-info {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .sendpage__content-coin {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0rem;
  }
  .sendPage__content-text textarea {
    font-size: 3.4vw;
    padding: 2vw;
  }
  .sendPage__content-text label,
  .sendPage__content-radio label {
    font-size: 11px;
    margin-top: 0rem;
  }
  .sendpage__content-coin-amount {
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    color: #0c1d87;
    font-weight: bold;
    margin-right: -60px;
  }
  .sendPage__content {
    width: 99%;
    margin: 3rem auto;
    padding-bottom: 2rem;
    
  }
  .sendPage__content-button {
    width: 100%;
    margin: auto;
    font-weight: 600;
  }
  .sendpage__content-coin img {
    width: 24vw;
    margin-bottom: 1rem;
    margin-left: 5rem;
    margin-top: -2rem;
  }
  .sendpage__content-profile {
    margin-top: 1rem;
  }
}
