.values {
  display: flex;
  align-items: center;
  width: 100%;
  color: white;
  font-family: "Inter", sans-serif;
  margin: 0.5rem 0rem;
}

.values__content {
  flex: 1;
  font-size: 20px;
  font-weight: 400;
}

.values__content-coin img {
  width: 1.8rem;
  margin-top: 0.2rem;
  /* margin-right: -0.5rem; */
}

.values__content-right {
  display: flex;
  align-items: center;
}

.values__content-right-coin {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.values__content-right-coin img {
  width: 80%;
  height: 80%;
  margin-top: 1px;
}

.values__content-right-value {
  font-size: 14px;
}

@media screen and (max-width: 450px) {
  .values {
    display: flex;
    align-items: center;
    width: 100%;
    color: white;
    font-family: "Inter", sans-serif;
    max-height: 50px;
    margin: 0.5rem 0rem;
  }
  .values__content-value {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .values__content-coin img {
    width: 1.7rem;
    margin-top: 0.2rem;
    margin-right: -0rem;
  }
  .profile__secondpart-transaction {
    width: 90%;
    margin: auto;
  }
}
